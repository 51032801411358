<template>
  <Transition name="fade">
    <nav
      v-if="isHamburgerMenuOpen"
      ref="panel"
      :aria-expanded="isHamburgerMenuOpen"
      class="fixed left-0 top-[--globalHeaderHeightMobile] z-50 hidden w-full bg-gray-200 lg:top-[--globalHeaderHeightDesktopCondensed] lg:block"
    >
      <div class="container">
        <div class="relative py-[4.5rem]">
          <button
            class="ease-out-expo absolute right-0 top-7 flex size-10 items-center justify-center rounded-full bg-white text-gray-700 transition-all duration-700 hover:scale-110 hover:text-gray-900"
            @click="toggleHamburgerMenu(false)"
          >
            <span class="sr-only">Close navigation menu</span>
            <UiIcon icon="close" size="md" />
          </button>
          <ul
            v-for="menu in headerNavigation"
            :key="menu._uid"
            role="menubar"
            class="grid grid-cols-3 items-center gap-9"
          >
            <li
              v-for="menuItem in removeMobileOnlyMenuItems(menu?.menuItems)"
              :key="menuItem._uid"
              class="group col-span-1 flex h-full flex-col gap-y-5"
            >
              <h2 class="text-primary-500 text-xl font-bold">
                <NuxtLink
                  :to="formatLink(menuItem.link?.cached_url)"
                  :target="menuItem.link?.target"
                  :rel="
                    isExternalLink(menuItem.link?.cached_url)
                      ? 'external'
                      : undefined
                  "
                >
                  {{ menuItem.label }}
                </NuxtLink>
              </h2>
              <template
                v-for="childMenu in menuItem.childMenu"
                :key="childMenu._uid"
              >
                <ul
                  v-for="childNavigationMenu in filterMenu(childMenu.menuItems)"
                  :key="childNavigationMenu._uid"
                  class="flex flex-col gap-y-2"
                >
                  <li
                    v-for="childMenuItem in childNavigationMenu.menuItems"
                    :key="childMenuItem._uid"
                    class="text-sm text-gray-700"
                  >
                    <NuxtLink
                      :to="formatLink(childMenuItem.link?.cached_url)"
                      :target="childMenuItem.link?.target"
                      :rel="
                        isExternalLink(childMenuItem.link?.cached_url)
                          ? 'external'
                          : undefined
                      "
                      class="ease-out-expo transition-colors duration-700 hover:text-gray-900"
                    >
                      {{ childMenuItem.label }}
                    </NuxtLink>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </Transition>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import type { BlockNavigationMenuStoryblok } from '@/types/storyblok'
import { removeMobileOnlyMenuItems } from '@/utils/common-utils'
import { formatLink, isExternalLink } from '@/utils/link-utils'

const panel = ref<HTMLElement | null>(null)

const uiStore = useUIStore()
const { toggleHamburgerMenu } = uiStore
const { isHamburgerMenuOpen } = storeToRefs(uiStore)

const { headerNavigation } = await useConfigStore().asyncConfig()

onClickOutside(panel, (event) => {
  if ((event.target as HTMLElement).dataset.action === 'toggleNavigationMenu') {
    return
  }

  toggleHamburgerMenu(false)
})

function filterMenu(
  menus: BlockNavigationMenuStoryblok[] | undefined,
): BlockNavigationMenuStoryblok[] | undefined {
  if (menus?.length) {
    return [menus[0]]
  }
}
</script>
